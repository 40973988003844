<template>
<div id="kt_body" class="header-fixed header-mobile-fixed subheader-enabled sidebar-enabled page-loading" style="height: 100%;">
  <div class="d-flex flex-column flex-root" style="height: 100%;">
    <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15">
        <!--        :style="{ backgroundImage: `url(${backgroundImage})` }"-->
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside header-->
          <a href="#" class="flex-column-auto mt-5 pb-lg-0 pb-10">
            <span class="svg-icon svg-icon-8x">
              <inline-svg src="/media/logos/alioth_icon.svg" />
            </span>
            <!--<img src="media/logos/alioth_icon.svg" class="max-h-70px" alt="" />-->
          </a>
          <!--end: Aside header-->
          <!--begin: Aside content-->
          <div class="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 class="font-size-h1 mb-5 text-white">Welcome to Alioth!</h3>
            <p class="font-weight-lighter text-white opacity-80">Ultimate web products.</p>
          </div>
          <!--end: Aside content-->
          <!--begin: Aside footer for desktop-->
          <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            <div class="opacity-70 font-weight-bold text-white">© 2021 Alioth</div>
            <!--              <div class="d-flex">-->
            <!--                <a href="#" class="text-white">Privacy</a>-->
            <!--                <a href="#" class="text-white ml-10">Legal</a>-->
            <!--                <a href="#" class="text-white ml-10">Contact</a>-->
            <!--              </div>-->
          </div>
          <!--end: Aside footer for desktop-->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <router-view />
      <!--end::Content-->
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Auth',
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return `${process.env.BASE_URL}media/bg/bg-4.jpg`
    },
  },
}
</script>
<!-- Load login custom page styles -->
<style lang="scss">
  @import '@/assets/sass/pages/login/login-1.scss';
</style>
